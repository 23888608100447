
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import BackBtn from '../../Components/BackBtn/BackBtn'
import Layout from '../../Components/Layout/Layout'
import { ContextAPI } from '../../common/Context/ContextApi'
import { ManageApis } from '../../common/APIS/ManageApis'
import BranchListDropdown from '../../Components/BranchList/BranchList'
import InputField from '../../Components/InputField/InputField'
import { FailedPopup, customLogger, sanitizedText } from '../../common/Utils/Utils'
import { useNavigate } from 'react-router-dom'
import { Spin } from '@arco-design/web-react'

const InitialValue = {
    branch_id: '',
    chitty_no: "",
    chitty_class: 'A',
    chitty_division: '1',
    chittal_no: '',
    chittal_name: '',
}
function QuickPayForm({index,setIsLoading,setIsFormShowing,isFormShowing}) {
    const [visible, setVisible] = useState(true)
    const [showSelectBranch, setShowSelectBranch] = useState(false)
    const [dates, setdates] = useState({ startDate: "", endDate: "" })
    const [quickpayForm, setquickpayForm] = useState({ ...InitialValue })
    const [branch_name, setbranch_name] = useState("")
    const { token } = useContext(ContextAPI)
    const [Errors, setErrors] = useState({})
    const navigate = useNavigate()
    //const [List, setList] = useState([])
    //const [auctions, setauctions] = useState([])
    //const [emptyListMessage, setemptyListMessage] = useState('')
    //const CurrentDate = new Date()
    const validateLength = (text, length) => text.slice(0, length).replace(' ', '').trim()

    useEffect(() => {
        setquickpayForm(InitialValue)
        setErrors({})
        handlePressClearFilter()
        

    }, [])

    const isChitty = index === 0

    const handleClearBranch = () => {
        //customLogger(dates.endDate, dates.startDate)
        //setList([])
        setquickpayForm(prev => ({ ...prev, branch_id: '', }));
        setbranch_name("")
    }

    const handleOnchange = (text, input) => {
        const regex = /[^0-9\/]/g;
        if ((["chitty_no", "chittal_no"].includes(input))) {
            setquickpayForm((prevState) => ({ ...prevState, [input]: text.replace(regex, '') }));
        } else {
            setquickpayForm((prevState) => ({ ...prevState, [input]: text }));
        }
    };

    // validate field and set error to state
    const handleError = (error, input) => {
        setErrors((prevState) => ({ ...prevState, [input]: error }));
    };

    const validate = async () => {
        let isValid = true;
        if (!quickpayForm.branch_id) {
            handleError("Please select a Branch", "branch_id");
            isValid = false;
        }
        if (!quickpayForm.chitty_no) {
            handleError(isChitty ? "Please enter chitty number" : "Please enter loan account number", "chitty_no");
            isValid = false;
        }
        if (!isChitty) {
            if (quickpayForm.chitty_no.length < 14) {
                handleError("Please enter a valid Account Number", "chitty_no")
                isValid = false;
            }
            if (!quickpayForm.chittal_name) {
                handleError("Please enter  loanee name", "chittal_name");
                isValid = false;
            }
            if (quickpayForm.chittal_name?.length < 3) {
                handleError("Please enter a valid loanee name", "chittal_name");
                isValid = false;
            }
        } else {
            if (!quickpayForm.chitty_class) {
                handleError("Please choose a chitty class", "chitty_class");
                isValid = false;
            }
            if (!quickpayForm.chitty_division) {
                handleError("Please choose a chitty_division", "chitty_division");
                isValid = false;
            }
            if (!quickpayForm.chittal_no) {
                handleError("Please enter chittal number", "chittal_no");
                isValid = false;
            }
            if (!quickpayForm.chittal_name) {
                handleError("Please enter  chittal name", "chittal_name");
                isValid = false;
            }
            if (quickpayForm.chittal_name?.length < 3) {
                handleError("Please enter a valid chittal name", "chittal_name");
                isValid = false;
            }
        }
        
        if (isValid) {
            //customLogger("#####   READY TO CALL API  #####");
            submitRequest();
        } 
    };

    const handlePressClearFilter = () => {
        setVisible(false)
        handleClearBranch()
        setbranch_name('')
        setShowSelectBranch(false)
        //setauctions([])
        //setemptyListMessage('')
        setquickpayForm(InitialValue)
    }


    const submitRequest = async () => {
        setIsLoading(true);
        const reqstBody = isChitty ? quickpayForm : {
            "branch_id": quickpayForm.branch_id,
            "loan_account_no": quickpayForm.chitty_no,
            "loanee_name": quickpayForm.chittal_name
        }


        //customLogger("reqstBody", reqstBody);
        // return
        //let { token } = UserData
        
        let path = isChitty ? global.getCustomerChitdetails : global.getCustomerLoandetails
        
        let response = await ManageApis("post", path, reqstBody, token)
        
        if (response.message == 'Success') {
            setIsLoading(false);
            //customLogger("res", response)
            
            const { status, message, data } = response.response_data || {}
            if (status) {
                if (response.response_data.data.blocked?.status) {
                    FailedPopup(response.response_data.data.blocked.message);
                    
                }else{
                    if (isChitty) {
                        navigate("/chittaldetails", {
                            state: { 
                                chittal_id: data.chittal_details._id || data.chittal_details.account_id,
                                chittal_no: quickpayForm.chittal_no,
                                chit_id: data.chit_details.chit_id,
                                branch_id: quickpayForm.branch_id,
                                quickPaydata: { ...data, ...quickpayForm ,remarks:data.remarks},
                                ChitDetails: data.chit_details
                             }
                        })
                    } else {
                        navigate(`/loandetails`, {
                            state: { 
                                quickPaydata: data
                             }
                        })
                    }
                    setIsLoading(false);
                }
                
            } else {
                setIsLoading(false);
                //customLogger("err", response)
                FailedPopup(response);
            }
        } else {
            setIsLoading(false);
            //customLogger("err", response)
            //debugger
            FailedPopup(response);
        }

    };

    const B = (props) => <span className='text_master2_d1 fw-bold'>{props.children}</span>


    const chittyClass = ['A', 'B', 'C', 'D'].map(item => ({ key: item, value: item }))
    const chittyDivision = () => {
        let arr = []
        for (let i = 1; i <= 16; i++) {
            arr.push({ key: `${i}`, value: `${i}` })
        }
        return arr
    }



    return (
      <div>
        <div className='row'>
          {isChitty && (
            <div className='col-12'>
              <div className='rounded p-2 mb-3 font_13 bg_light2'>
                <B>Note :</B> Follow the below format to enter chitty details.
                For example, if the chitty number shown in passbook is{" "}
                <B>88/2021/A/1</B> Chitty Number: <B>88/2021</B> Chitty Class:{" "}
                <B>A</B> Chitty Division: <B>1</B>
              </div>
            </div>
          )}
          <div className='col-12 col-md-6 col-lg-4'>
            <div className='mb-3'>
              <BranchListDropdown
                //isUserBranch
                isRequied
                selectedItem={(text) => handleOnchange(text, "branch_id")}
                label={"Branch"}
                errorMessage={Errors.branch_id}
                seterrorMessage={handleError}
                onChange={(text) => handleOnchange(text, "branch_id")}
                onFocus={() => handleError(null, "branch_id")}
                onClear={handleClearBranch}
                textValue={branch_name}
                selectedItemString={(param) => setbranch_name(param)}
                value={quickpayForm.branch_id}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-4'>
            <div className='mb-3'>
              <InputField
                required
                label={isChitty ? "Chitty Number" : "Loan Account Number"}
                type={"text"}
                onchange={(text) => handleOnchange(text, "chitty_no")}
                onfocus={() => handleError(null, "chitty_no")}
                errorMessage={Errors.chitty_no}
                seterrorMessage={handleError}
                value={quickpayForm.chitty_no}
                name={"chitty_no"}
                maxLen={isChitty ? 8 : 14}
              />
            </div>
          </div>
          {isChitty && (
            <>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='mb-3'>
                  <InputField
                    required
                    label={"Chitty Class"}
                    type={"select"}
                    onchange={(text) => handleOnchange(text, "chitty_class")}
                    onfocus={() => handleError(null, "chitty_class")}
                    errorMessage={Errors.chitty_class}
                    seterrorMessage={handleError}
                    options={chittyClass}
                    value={quickpayForm.chitty_class}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='mb-3'>
                  <InputField
                    required
                    label={"Chitty Division"}
                    type={"select"}
                    onchange={(text) => handleOnchange(text, "chitty_division")}
                    onfocus={() => handleError(null, "chitty_division")}
                    errorMessage={Errors.chitty_division}
                    seterrorMessage={handleError}
                    options={chittyDivision()}
                    value={quickpayForm.chitty_division}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='mb-3'>
                  <InputField
                    required
                    label={"Chittal Number"}
                    type={"text"}
                    onchange={(text) => handleOnchange(text, "chittal_no")}
                    onfocus={() => handleError(null, "chittal_no")}
                    errorMessage={Errors.chittal_no}
                    seterrorMessage={handleError}
                    value={quickpayForm.chittal_no}
                    name={"chittal_no"}
                  />
                </div>
              </div>
            </>
          )}
          <div className='col-12 col-md-6 col-lg-4'>
            <div className='mb-3'>
              <InputField
                required
                label={isChitty ? "Chittal Name" : "Loanee name"}
                type={"text"}
                placeholder={`${
                  isChitty ? "Chittal Name" : "Loanee name"
                } (Min. First 3 Characters)`}
                onchange={(text) => handleOnchange(text, "chittal_name")}
                onfocus={() => handleError(null, "chittal_name")}
                errorMessage={Errors.chittal_name}
                seterrorMessage={handleError}
                value={quickpayForm.chittal_name}
                name={"chittal_name"}
              />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-end'>
            {isFormShowing && (
              <button
                className='btn px_btn btn-master-line py-1 rounded fw_500 font_12  text-nowrap flex-fill flex-md-grow-0 me-2'
                //   onClick={() => handleInfo(itm)}
                onClick={()=> setIsFormShowing(false)}
                >
                Cancel
              </button>
            )}
            <button
              className='btn btn-master flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded'
              onClick={validate}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
}

export default QuickPayForm
