import { CalculatorOutline } from "react-ionicons"
import profileImg from "../../assets/images/profile_img.png"
import PopupModal from "../../Components/PopupModal"
import Layout from "../../Components/Layout/Layout"
import { ManageApis } from "../../common/APIS/ManageApis"
import { useContext, useEffect, useMemo, useState } from "react"
import { ContextAPI } from "../../common/Context/ContextApi"
import { FailedPopup, FormValidation, clearSessionStorage, customLogger, firstCamelCase, resetNavigation, successPopup } from "../../common/Utils/Utils"
import BackBtn from "../../Components/BackBtn/BackBtn"
import InputField from "../../Components/InputField/InputField"
import VerifyOTP from "../VerifyOTPScreen/VerifyOTP"
import { useNavigate } from "react-router-dom"


const countries = [
  { value: "India", key: "India" }
]
const state = [
  { value: "Kerala", key: "Kerala" }
]
function ProfileScreen() {
  const navigate = useNavigate()
  const { token,settoken,setkycModal,UserData } = useContext(ContextAPI)
  const [userDetails, setuserDetails] = useState({})
  const [isLoading, setisLoading] = useState(false)
  const [PermanentAddress, setPermanentAddress] = useState([])
  const [PresentAddress, setPresentAddress] = useState([])
  const [OldPresentAddress, setOldPresentAddress] = useState([])
  const [OfficialAddress, setOfficialAddress] = useState([])
  const [EditAddressForm, setEditaddressForm] = useState([])
  const [editAddressModal, seteditAddressModal] = useState(false)
  const [changePasswordModal, setchangePasswordModal] = useState(false)
  const [changeMobileModal, setchangeMobileModal] = useState(false)
  const [changeEmailModal, setchangeEmailModal] = useState(false)
  const [verifyKycModal, setverifyKycModal] = useState(false)
  const [Errors, setErrors] = useState([])
  const [districtList, setdistrictList] = useState([])
  const [EditingAddresType, setEditingAddresType] = useState('')
  const [mobile, setmobile] = useState('')
  const [OtpSendResponse, setOtpSendResponse] = useState()
  const [passwords, setpasswords] = useState({
    current_password: '',
    new_password: '',
    confirm_password: ''
  })
  const [email, setEmail] = useState("");
  const [emailEditType, setemailEditType] = useState('')
  const [isOpenOTPModal, setisOpenOTPModal] = useState(0)

  // call initial apis
  useEffect(() => {
    getUserDetails()
    getAddressDetails()
    return () => {
      setuserDetails({})
    }
  }, [token])
  // district api calling
  useEffect(() => {
    if (editAddressModal) {
      ListDistrict()
    }
    return (() => setdistrictList([]))
  }, [editAddressModal])

  const forVerify = useMemo(() => emailEditType.includes('verify'), [emailEditType])



  // creating a default error array with empty message 
  useEffect(() => {
    if (EditAddressForm) {
      setErrors(PresentAddress.map(item => ({ name: item.key, message: '' })));
    } if (changeMobileModal) {
      setErrors([{ name: 'mobile', message: '' }]);
    } if (changeEmailModal) {
      setErrors([{ name: 'email', message: '' }]);
    } if (changePasswordModal) {
      // Creating an array with passwords state keys
      setErrors(Object.keys(passwords).map(item => ({ name: item, message: '' })));
    }
    return () => setErrors([]);
  }, [EditAddressForm, changeEmailModal, changeMobileModal, changePasswordModal]);



  const getUserDetails = async () => {
    //debugger
    setisLoading(true)
    let path = global.getProfileDetails

    // debugger
    ManageApis('get', path, '', token)
      .then((res) => {
        // customLogger('user details response', res.response_data)
        if (res.response_data.status) {
          setuserDetails(res.response_data.data.personal_details)
          setmobile(res.response_data.data.personal_details.mobile)
          setisLoading(false)
        } else {
          setisLoading(false)
        }
      }
      )
      .catch((err) => 
      {
        customLogger('err --user details response', err)
        setisLoading(false)
      })

  }

  const getAddressDetails = async () => {
    setisLoading(true)




    // let response = await ManageApis("post", getCustomerProfile, reqstBody, token)
    let response = await ManageApis("get", global.getAddressDetails, "", token)

    if (response.message == 'Success') {
      setisLoading(false)
      customLogger("res", response)

      // let addressArray = response.response_data.data.customerProfile.addressDTOs
      let addressArray = response.response_data.data.address_details.address

      let PermanentAddressArray = addressArray.filter((itm) => itm.type == '1')

      let CommunicationAddressArray = addressArray.filter((itm) => itm.type == '3')

      let OfficialAddressArray = addressArray.filter((itm) => itm.type == '2')
      // debugger
      let array1 = []
      if (PermanentAddressArray[0]) {
        Object.keys(PermanentAddressArray[0]).forEach(key => (array1.push({
          // 'key': splitCamelCase(key), 'value': PermanentAddressArray[0][key]
          'key': key.replaceAll('_', ' ').trim(), 'value': PermanentAddressArray[0][key]
        }))) //creating an from objects
        // let removeItems = ['Id', 'Branch', 'Line1','Fax1', 'Fax2']
        let removeItems = ['address type', 'address type text', 'type', 'id']
        setPermanentAddress(array1.filter(item => !removeItems.includes(item.key)))
      }
      let array2 = []
      if (CommunicationAddressArray[0]) {
        Object.keys(CommunicationAddressArray[0]).forEach(key => (array2.push({
          'key': key.replaceAll('_', ' ').trim(), 'value': CommunicationAddressArray[0][key]
        }))) //creating an from objects
        let removeItems2 = ['type', 'id', 'address type', 'address type text']
        setPresentAddress(array2.filter(item => !removeItems2.includes(item.key)))
        setOldPresentAddress(array2.filter(item => !removeItems2.includes(item.key)))

      }



      let array3 = []
      if (OfficialAddressArray[0]) {

        Object.keys(OfficialAddressArray[0]).forEach(key => (array3.push({
          // 'key': splitCamelCase(key), 'value': OfficialAddressArray[0][key]
          'key': key.replaceAll('_', ' ').trim(), 'value': OfficialAddressArray[0][key]
          //'key': key, 'value': OfficialAddressArray[0][key]
        }))) //creating an from objects
        let removeItems3 = ['type', 'id', 'address type', 'address type text']
        setOfficialAddress(array3.filter(item => !removeItems3.includes(item.key)))
      }

    } else {
      setisLoading(false)
      customLogger("err", response)
      FailedPopup(response)
    }
  }

  // list District
  const ListDistrict = async () => {
    //setIsLoading(true);
    const path = global.listdistricts;
    let response = await ManageApis("get", path, "", token);
    if (response.message == "Success") {
      customLogger("listdistricts", response.response_data.data.docs)
      let temArray = response.response_data.data.docs;
      // let newArr = temArray.filter((item) => item.scheme_id == 2);
      // let subschemeArray = newArr[0].sub_schemes;
      let temArray1 = [];
      Object.keys(temArray).forEach((key) =>
        temArray1.push({
          key: temArray[key].name,
          value: temArray[key].district_id
        })
      );
      setdistrictList(temArray1);
      customLogger("listdistricts", temArray1);
      //setIsLoading(false);
    } else {
      //setIsLoading(false);
      FailedPopup(response);
    }
  };
  // to open all modals
  const handleOpenModal = (type) => {
    //debugger
    switch (type) {
      case 'password':
        setpasswords({
          current_password: '',
          new_password: '',
          confirm_password: ''
        })
        setchangePasswordModal(true)
        break;
      case 'mobile':
        setchangeMobileModal(true)
        setmobile('')
        
        break;
      case 'create-email':
        setchangeEmailModal(true)
        setemailEditType(type)
        setEmail('')
        break;
      case 'change-email':
        setchangeEmailModal(true)
        setemailEditType(type)
        setEmail('')
        break;  
      case 'verify-email':
        setchangeEmailModal(true)
        setemailEditType(type)
        setEmail(userDetails.email)
        break;
      case 'address-present':
        seteditAddressModal(true)
        setEditaddressForm(PresentAddress)
        setEditingAddresType(type)
        break;
      case 'address-official':
        seteditAddressModal(true)
        setEditingAddresType(type)
        setEditaddressForm(OfficialAddress)
        break;

      default:
        break;
    }
  }
  // to close all modals
  const handleCloseModal = (type) => {
    switch (type) {
      case 'password':
        setchangePasswordModal(false)
        break;
      case 'mobile':
        setchangeMobileModal(false)
        setmobile('')
        setOtpSendResponse("")
        
        break;
      case 'email':
        getUserDetails()
        if (!OtpSendResponse) {
          setchangeEmailModal(false)
          setemailEditType('')
        }
          
        break;

      case 'address':
        seteditAddressModal(false)
        setEditaddressForm([])
        setEditingAddresType('')
        break;


      default:
        break;
    }
  }
  const handleEditAddress = (value, name) => {
    customLogger(value, name)
    setEditaddressForm(EditAddressForm.map(item => item.key == name ? { ...item, value: value } : item))
  }


  const handleErrors = (name, message) => {
    customLogger(name, message)
    if (Errors.length) {
      if (name.includes('new_password')) {
				message=="Enter a strong Password" ?
				setErrors(Errors.map(item => item?.name === name ? { name: name, message: "Password must be a minimum of 8 characters, special chars, uppercase, and lowercase letters and numbers." } : item))
				: setErrors(Errors.map(item => item?.name === name ? { name: name, message } : item))
			}else{
				setErrors(Errors.map(item => item?.name === name ? { name: name, message } : item))
			}
    }
    else setErrors(prev => [...prev, { name: name, message }])
  }

  const handleErrorMessage = (item) => Errors.length ? Errors.find(itm => itm.name == item)?.message : ''


  // update address function
  const handleSavePress = async () => {
    setisLoading(true)
    // setIsEdited(!isEdited)
    // setisEditable(!isEditable)
    let convertedArray = EditAddressForm.reduce((obj, item) => {
      let { key, value } = item;
      key = key.replaceAll(' ', '_')
      obj[key] = value;
      return obj;
    }, {});

    customLogger("new  array", convertedArray);
    let path = EditingAddresType.includes('present') ? global.updateCommunicationAddress : global.updatePresentAddress
    //debugger
    let response = await ManageApis("post", path, convertedArray, token);
    customLogger("res", response)
    const { status, message, data } = response.response_data || {}
    if (status) {
      setisLoading(false)
      successPopup(message)
      // closing modal
      seteditAddressModal(false)
      // fetch new data from api
      getAddressDetails()
    } else {
      setisLoading(false)
      customLogger("err", response)
      FailedPopup(response)

    }
  }

  const handleChangeMobile = (value, name) => {
    setmobile(value)
  }
  // passwords onchange
  const handleChangePassword = (value, name) => {
    setpasswords(prev => ({
      ...prev,
      [name]: value
    }));
    // if (name == 'confirm_password') {
    //   passwords.new_password !== value && setErrors(Errors.map(item => item?.name === name ? { name, message: 'Password and Confirm Password does not match.' } : item))
    // }
    // if(name == 'new_password'){
    //   //passwords.confirm_password !== value && setErrors(Errors.map(item => item?.name === 'confirm_password' ? { name:'confirm_password', message: 'Password and Confirm Password does not match.' } : item))
    //   passwords.confirm_password == value && setErrors(Errors.map(item => item?.name === 'confirm_password' ? { name:'confirm_password', message: '' } : item))
    // }
  }
  //  email onchange
  const handleEmailChange = (value) => setEmail(value)

  const handleClearAuth = async () => {
    await clearSessionStorage()
    settoken('')
    return navigate('/login', { replace: true });
}


  // change password
  const handleSubmitpasswordsChange = async () => {

    // creating an array from state
    let requiredItems = Object.keys(passwords)
    // array passing for empty validation
    let validationResult = FormValidation(passwords, requiredItems)
    if (passwords.new_password.toLowerCase().includes(UserData.username.toLowerCase())) {
      FailedPopup("Password cannot contain the username")
      return
    }
    if(passwords.new_password == passwords.confirm_password){
      // setErrors(Errors.map(item => item?.name === 'confirm_password' ? { name:'confirm_password', message: '' } : item))
      if (validationResult.validation) {
        if (!validate()) {
  
          const reqstBody = {
            current_password: passwords.current_password,
            new_password: passwords.new_password
          }
          // customLogger(reqstBody);
          // customLogger(token);
          let response = await ManageApis("post", global.updatePassword, reqstBody, token)
          // customLogger('res', response);
          if (response.message === "Success") {
            // customLogger("response", response)
            successPopup(response.response_data.message)
            // setTimeout(() => {
            //   resetNavigation("/login")
            // }, 1000);
            setTimeout(() => {
               handleClearAuth()
            }, 500);
           
          } else {
            customLogger("response", response)
            FailedPopup(response)
          }
        }
      } else {
        if (validationResult.failedItems.length) {
          let requiredItems = Object.keys(passwords).map(item => ({ name: item, message: validationResult.failedItems.includes(item) ? 'Field is required' : '' }))
          setErrors(requiredItems)
        }
      }
    }else{
      //setErrors(Errors.map(item => item?.name === 'confirm_password' ? { name:'confirm_password', message: 'Password and Confirm Password does not match.' } : item))
      FailedPopup("Password and Confirm Password does not match.")
    }
    

  }

  // handle submit for update email
  const handleSubmitMail = async () => {

    if (email) {
      if (!validate()) {
        setisLoading(true);
        const reqstBody = {
          email_id: email,
        };
        //debugger
        if (emailEditType.includes('verify')) {
          if(userDetails?.email == email){
            // verify emailid 
            let response = await ManageApis("post", global.sentValidateEmail, reqstBody, token);
            if (response.message == "Success") {
              setisLoading(false);
              customLogger("response", response);
              setOtpSendResponse(response.response_data.data)
              setisOpenOTPModal(1)
            } else {
              setisLoading(false);
              FailedPopup(response);
            }
          }else{
            setisLoading(false);
            FailedPopup("Incorrect Email ID. Please check the Email ID and try again.");
          }
          

        } else {
          if(userDetails?.email == email){
            FailedPopup("Please enter a different mail id");
            setisLoading(false);
          }else{
            // create or change emailid 
            let response = await ManageApis("post", global.sentChangeEmailOtp, reqstBody, token);
            if (response.message == "Success") {
              setisLoading(false);
              customLogger("response", response);
              setOtpSendResponse(response.response_data.data)
              setisOpenOTPModal(1)
            } else {
              setisLoading(false);
              FailedPopup(response);
            }
          }
          
        }
      }else{
        setisLoading(false);
      }
    } else {
        let requiredItems = [{ name: 'email', message: 'Field is required' }]
        setErrors(requiredItems)
      }

  };

  const validate = () => Errors.some(item => item?.message)


  // mobile change api
  const handleSubmitMobile = async () => {
    //debugger
    setisLoading(true);
    if (!validate()) {
      const reqstBody = {
        mobile,
      };
      // change emailid axios call here
      let response = await ManageApis(
        "post",
        global.sendMobileOTP,
        reqstBody,
        token
      );

      if (response.message == "Success") {
        setisLoading(false);
        customLogger("response", response);
        // setSendOtp(false);
        setOtpSendResponse(response.response_data.data)
        successPopup(response.response_data.message)

      } else {
        setisLoading(false);
        // setSendOtp(true);
        FailedPopup(response);
      }
    }
  };

  const handleOtpVerification = async (otp) => {
    setisLoading(true)
    const rqstBody = {
      otp: OtpSendResponse?.token_alphabet_part + otp,
      mobile,
    };
    // axios call for verify mobile
    let response = await ManageApis("post", global.verifyMobileOTP, rqstBody, token);
    // if axios call success for verify mobile
    if (response.message == "Success") {
      // customLogger("Token", response.response_data.data.token);
      setisLoading(false)
      successPopup(response.response_data.message)
      getUserDetails()
      setOtpSendResponse('')
      setchangeMobileModal(false)

    } else {
      setisLoading(false)
      customLogger("Token", response.response_data)
      FailedPopup(response);

      // customLogger("Token", response.response_data.data.token);

    }
  }

  const handleResendOTP = async () => {
    setisLoading(true)

    const rqstBody = {
      mobile
    };

    // customLogger(rqstBody);
    let response = await ManageApis("post", global.reSendMobileOTP, rqstBody, token);
    if (response.message == "Success") {
      setisLoading(false)
      setOtpSendResponse(response.response_data.data)
      customLogger(response.response_data.data.message)

    } else {
      customLogger(response)
      setisLoading(false)
      FailedPopup(response);
    }
  };

  //const handleEmailResendOTP = async () => {}
  // axios call for resend otp
  const handleEmailResendOTP = async (otp) => {
    otp = ""
    //debugger
    setisLoading(true)
    let reqstBody = {
      email_id: email,
    };
    let path;
    // if (!userDetails.is_email_verified) {
    //   path = global.reSentValidateEmail
    //   reqstBody = { ...reqstBody, otp: OtpSendResponse?.token_alphabet_part + otp }
    // } else {
    //   // checking the current stage if its 1 the path will be resend change email otp otherwise the pathh will be email otp
    //   if (isOpenOTPModal == 1) {
    //     path = global.reSentChangeEmailIdOtp
    //   } else if (isOpenOTPModal == 2) {
    //     path = global.reSentChangeEmail
    //   }
    // }
    if (emailEditType.includes('verify')) {
      path = global.reSentValidateEmail
      reqstBody = { ...reqstBody, otp: OtpSendResponse?.token_alphabet_part + otp }
    }else{
      if (isOpenOTPModal == 1) {
        path = global.reSentChangeEmailIdOtp
      } else if (isOpenOTPModal == 2) {
        path = global.reSentChangeEmail
      }
    }


    
    
    let response = await ManageApis("post", path, reqstBody, token);
    if (response.message == "Success") {
      setisLoading(false)
      //customLogger(response, response?.response_data?.data?.token_alphabet_part)
      let token_alphabet_part = response.response_data.data
      if (token_alphabet_part) {
        //customLogger(token_alphabet_part)
        setOtpSendResponse(prev => ({ ...prev, ...token_alphabet_part }))
      }
      successPopup(response.response_data.message);
    } else {
      setisLoading(false)
      //customLogger(response)
      FailedPopup(response);
    }
  };


  const handleEmailOtpVerification = async (otp) => {
    //debugger
    let path;
    if (forVerify) {
      path = global.VerifyEmail
    } else {
      // checking the current stage if its 1 the path will be resend change email otp otherwise the pathh will be email otp
      if (isOpenOTPModal == 1) {
        path = global.verifyChangeEmailOtp
      } else if (isOpenOTPModal == 2) {
        path = global.verifyChangeEmail
      }
    }
    const reqstBody = {
      email_id: email,
      otp: OtpSendResponse?.token_alphabet_part + otp
    };

    // axios call for verify mobile
    let response = await ManageApis("post", path, reqstBody, token);
    customLogger({ response })
    // if axios call success for verify mobile
    if (response?.response_data?.status) {
      customLogger("", response);
      if (isOpenOTPModal == 2 || forVerify) {
        setisOpenOTPModal(0)
        setchangeEmailModal(false)
        setOtpSendResponse(response.response_data.data)
        getUserDetails()
        //navigate('Profile')
        successPopup(response?.response_data?.message)
      } else if (isOpenOTPModal == 1) {
        setisOpenOTPModal(2)
        setOtpSendResponse(response.response_data.data)
      } else {
        FailedPopup(response)
      }
      return "Success"
      //debugger
    } else {
      FailedPopup(response);
      return "Failed"
    }
  };



  const formatLabel = (item) => {
      const itemVal = item.replaceAll('_', ' ')
      const words = itemVal.split(" ")
      return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ");
  };

  return (
    <Layout loading={isLoading}>

      <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
        <div>
          <div className="mb-3">
            <div className='align-items-center d-flex justify-content-between flex-wrap'>

              <BackBtn title={'Profile'} />
            </div>
          </div>

          {userDetails?._id ?
          <div className="p-3 bg-white br_10 shadow_sm mb-3">
            <div>
              <div className="row">
                {/* <div className="col-12 col-lg-auto pe-0">
                  <div className="m-auto">
                    <div className="text-center">
                      <div className="d-inline-block">
                        <img src={profileImg} className="h_80x w_80x border border-5 rounded-circle" />
                      </div>
                    </div>

                  </div>
                </div> */}
                <div className="col-12 col-lg ps-1">
                  {/* <div className="border-start-0 border-lg-start h-100"> */}
                  <div className="h-100">
                    <div className="d-grid d-sm-flex mb-2">
                      <span className="px-2 w_115x font_14">
                        Name
                      </span>
                      <div className="pt-1 pt-sm-0 px-2 px-sm-0">
                        <span className="d-none d-sm-inline-block px-1 me-2">:</span>
                        <span className="fw_500">{userDetails?.first_name + ' ' + userDetails?.last_name}</span>
                      </div>
                    </div>
                    <div className="d-grid d-sm-flex mb-2">
                      <span className="px-2 w_115x font_14">
                        Date of Birth
                      </span>
                      <div className="pt-1 pt-sm-0 px-2 px-sm-0">
                        <span className="d-none d-sm-inline-block px-1 me-2">:</span>
                        <span className="fw_500">{userDetails.dob}</span>
                      </div>
                    </div>
                    <div className="d-grid d-sm-flex mb-2">
                      <span className="px-2 w_115x font_14">
                        Mobile
                      </span>
                      <div className="pt-1 pt-sm-0 px-2 px-sm-0">
                        <span className="d-none d-sm-inline-block px-1 me-2">:</span>
                        <span className="fw_500">{userDetails.mobile}</span>
                        <span className="badge badge-warning badge_success_soft font_12 fw_500 rounded-pill ms-2">Verified</span>
                        {/* <button className="btn btn-master-line px-3 py-0 font_13 ms-2 rounded-pill" onClick={() => handleOpenModal('mobile')}>Change</button> */}
                      </div>
                    </div>
                    <div className="d-grid d-sm-flex mb-2">
                      <span className="px-2 w_115x font_14 flex-shrink-0">
                        Email
                      </span>
                      <div className="d-grid d-sm-flex flex-wrap gap-2 pt-1 pt-sm-0 px-2 px-sm-0">
                        <span className="d-none d-sm-inline-block px-1">:</span>


                        
                          {userDetails?.email ?
                            <span className="fw_500 ">{userDetails.email}</span>
                          :
                            <span className="fw_500  font_12 text-warning">Your email is not in our records</span>
                          }

                        {(userDetails?.email && userDetails.is_email_verified) ?
                          <span className="badge badge-warning badge_success_soft font_12 fw_500 rounded-pill ">Verified</span>
                          : (userDetails?.email && !userDetails.is_email_verified) ?
                          <span className="badge badge-warning badge_warning_soft font_12 fw_500 rounded-pill ">Not Verified</span>
                          :""
                        }
                        
                        
                        
                        {(!userDetails.email) ?
                          <button className="btn btn-master-line px-2 py-0 font_13  rounded-pill" onClick={() => handleOpenModal('create-email')}>Add your email</button>
                          : (userDetails.email && !userDetails.is_email_verified) ?
                          <button className="btn btn-master-line px-2 py-0 font_13  rounded-pill" onClick={() => handleOpenModal('verify-email')}>Verify email</button>
                          : ""
                        }
                        {(userDetails.email) &&
                          <button className="btn btn-master-line px-2 py-0 font_13 rounded-pill" onClick={() => handleOpenModal('change-email')}>Change email</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-auto pt-2 pt-sm-3 pt-lg-0">
                  <div className="align-items-start d-grid d-sm-flex h-100 justify-content-sm-start justify-content-lg-end gap-2">
                    {userDetails.is_min_kyc_verified ?
                      <span className={`badge badge-warning badge_success_soft font_12 fw_500 px-3 py-2 rounded-pill`}>{'PAN ******'+ userDetails.pan_no.slice(-4) +' Verified'}</span>
                      :
                      <button className="btn btn-master flex-fill flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded" onClick={() => setkycModal(true)}>Update PAN</button>
                    }
                    <button className="btn btn-master  flex-lg-grow-0 font_12 fw_500 ms-sm-2 px-3 px_btn rounded" onClick={() => handleOpenModal('password')}>Change Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <></>

          }

          {(PermanentAddress.length > 0 || PresentAddress.length > 0 || OfficialAddress.length > 0)&&
          
          <div className="p-3 bg-white br_10 shadow_sm">
            <div>
              <h5 className="fw_600 font_16 text_master mb-3">Address Details</h5>

            </div>
            <div>
             {(PermanentAddress.length > 0)&&
              <div className="bd_master2_d1 border rounded mb-3">
                <div className="bg_light2 rounded py-1">
                  <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">Permanent Address</h3>
                </div>
                <div className="px-3 pt-3 pb-1">
                  <div className="row font_14">
                    {PermanentAddress.map(item =>
                      <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                        <div className="row">
                          <div className="col-12 col-sm-6">{firstCamelCase(item.key)}</div>
                          <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                            <div className="d-flex">
                              <span className="pe-1 d-none d-sm-block">:</span>
                              <span className="fw_500 text-black word_break">{item.value}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
             }
              {(PresentAddress.length > 0)&&
                <div className="bd_master2_d1 border rounded mb-3">
                  <div className="bg_light2 rounded py-1 d-flex justify-content-between">
                    <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">Present Address</h3>
                    {/* <div className="pe-3 pt-1">
                      <span onClick={() => handleOpenModal('address-present')} className="align-items-center bg_gray circle_27x cursor-pointer d-flex font_12 hover justify-content-center rounded-circle text_master">
                        <i className="fas fa-pen"></i>
                      </span>
                    </div> */}
                  </div>
                  <div className="px-3 pt-3 pb-1">
                    <div className="row font_14">
                      {PresentAddress.map(item =>
                        <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                          <div className="row">
                            <div className="col-12 col-sm-6">{firstCamelCase(item.key)}</div>
                            <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                              <div className="d-flex">
                                <span className="pe-1 d-none d-sm-block">:</span>
                                <span className="fw_500 text-black word_break">{item.value}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {(OfficialAddress.length > 0)&&
                <div className="bd_master2_d1 border rounded">
                  <div className="bg_light2 rounded py-1 d-flex justify-content-between">
                    <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">Official Address</h3>
                    {/* <div className="pe-3 pt-1">
                      <span onClick={() => handleOpenModal('address-official')} className="align-items-center bg_gray circle_27x cursor-pointer d-flex font_12 hover justify-content-center rounded-circle text_master">
                        <i className="fas fa-pen"></i>
                      </span>
                    </div> */}
                  </div>
                  <div className="px-3 pt-3 pb-1">
                    <div className="row font_14">
                      {OfficialAddress.map(item =>
                        <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                          <div className="row">
                            <div className="col-12 col-sm-6">{firstCamelCase(item.key)}</div>
                            <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                              <div className="d-flex">
                                <span className="pe-1 d-none d-sm-block">:</span>
                                <span className="fw_500 text-black word_break">{item.value}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
          }
         
        </div>
      </div>

      {/* EDIT ADDRESS MODAL */}
      {editAddressModal &&
        <PopupModal visible={editAddressModal} handleClose={() => handleCloseModal('address')} title="Edit Address" size="lg">
          <div>
            <div className="row">
              {EditAddressForm.map(item =>
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <div>
                      <InputField
                        type={['pincode', 'land line'].includes(item.key) ? 'mobile' : ['country', 'state', 'district'].includes(item.key) ? 'select' : 'text'}
                        label={item.key} name={item.key}
                        value={item.value}
                        onchange={handleEditAddress}
                        errorMessage={handleErrorMessage(item.key)}
                        seterrorMessage={handleErrors}
                        maxLen={(item.key == 'pincode') ? 6 : (item.key == 'land line') && 10}
                        required
                        options={item.key == 'country' ? countries : item.key == 'state' ? state : item.key == 'district' && districtList}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <button
                type="submit"
                className="btn px_btn btn-master px-4 rounded w-100 fw_500"
                onClick={handleSavePress}
              >
                Save
              </button>
            </div>
          </div>
        </PopupModal>
      }
      {/* CHANGE PASSWORD MODAL */}
      {changePasswordModal &&
        <PopupModal visible={changePasswordModal} handleClose={() => handleCloseModal('password')} title="Change Password" size="md">
          <div>
            {Object.keys(passwords).map(item => 
           
              <InputField 
                label={formatLabel(item)} 
                type={'password'} 
                name={item} 
                onchange={handleChangePassword} 
                required 
                seterrorMessage={handleErrors} 
                errorMessage={handleErrorMessage(item)} 
                helperText={(item == "new_password")? 'Note: Your password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and symbols.':"" }
                value={passwords[item]} 
              />
            )}
            <div>
              <button
                type="submit"
                className="btn px_btn btn-master px-4 rounded w-100 fw_500"
                onClick={handleSubmitpasswordsChange}
              >
                Save
              </button>
            </div>
          </div>
        </PopupModal>
      }


      {/* VERIFY MOBILE NUMBER MODAL */}
      {changeMobileModal &&
        <PopupModal visible={changeMobileModal} handleClose={() => handleCloseModal('mobile')} title="Change Mobile Number" size="md" >
          {OtpSendResponse ?
            <VerifyOTP verificationData={OtpSendResponse} handleSubmit={handleOtpVerification} handleResendOTP={handleResendOTP} handleBackBtn={() => setOtpSendResponse()} />
            :
            <div>
              <InputField type={'mobile'} label={'Mobile Number'} required onchange={handleChangeMobile} value={mobile} name={'mobile'} seterrorMessage={handleErrors} errorMessage={handleErrorMessage('mobile')} />
              <div>
                <button
                  type="submit"
                  className="btn px_btn btn-master px-4 rounded w-100 fw_500"
                  onClick={handleSubmitMobile}
                // disabled={validate}
                >
                  Submit
                </button>
              </div>
            </div>
          }
        </PopupModal>
      }
      {/* VERIFY EMAIL ID MODAL */}
      {changeEmailModal &&
        <PopupModal visible={changeEmailModal}  handleClose={() => handleCloseModal('email')} title={`${emailEditType.includes('verify') ? 'Verify' : emailEditType.includes('create') ? 'Create': 'Change'} Email ID`} size="md" closeButton={!OtpSendResponse}> 
        {/* close button needed in first screen not reuired in otp component */}
          {OtpSendResponse ?
            (isOpenOTPModal == 1) ? 

              <VerifyOTP verificationData={OtpSendResponse} disabled={isLoading} handleSubmit={handleEmailOtpVerification} handleResendOTP={handleEmailResendOTP} handleBackBtn={() => setOtpSendResponse()} />  
              
              : 
              (isOpenOTPModal == 2) &&
              <VerifyOTP verificationData={OtpSendResponse} disabled={isLoading} handleSubmit={handleEmailOtpVerification} handleResendOTP={handleEmailResendOTP} handleBackBtn={() => setOtpSendResponse()} />
            
            
            :
            <div>
              <div className="font_12 mb-2 text_master">
              An OTP will be sent to the registered mobile number, on confirming it a confirmation email will be sent to your new Email Id with the verification code.
              </div>
              {emailEditType.includes('verify') ? 
                <div className="bg_gray2 border br_5 mb-3 px-3 py-2 text_gray1">{email}</div>
              :
                <InputField type={'email'} disabled={emailEditType.includes('verify')} onchange={handleEmailChange} value={email} name={'email'} label={'Email'}
                 errorMessage={handleErrorMessage('email')} seterrorMessage={handleErrors} required  />
              }
              
              <div>
                <button
                  type="submit"
                  className="btn px_btn btn-master px-4 rounded w-100 fw_500"
                  onClick={handleSubmitMail}
                  disabled={isLoading}
                >
                  Submit
                </button>
              </div>
            </div>
          }
        </PopupModal>
      }
    </Layout>
  )
}
export default ProfileScreen