import moment from "moment";

function inferDateFormat(dateString) {
  if (!dateString) return null;

  // List of common date formats to check
  const formatsToCheck = [
    "YYYY-MM-DD", // yyyy-mm-dd
    "DD-MM-YYYY", // dd-mm-yyyy
    "MM-DD-YYYY", // mm-dd-yyyy
    "YYYY/MM/DD", // yyyy/mm/dd
    "DD/MM/YYYY", // dd/mm/yyyy
    "MM/DD/YYYY", // mm/dd/yyyy
    "YYYY.MM.DD", // yyyy.mm.dd
    "DD.MM.YYYY", // dd.mm.yyyy
    "MM.DD.YYYY", // mm.dd.yyyy
    "YYYY MM DD", // yyyy mm dd
    "DD MM YYYY", // dd mm yyyy
    "MM DD YYYY", // mm dd yyyy
    "YYYY-MM-DDTHH:mm:ssZ", // ISO 8601 format with UTC offset
    "YYYY-MM-DDTHH:mm:ss.SSSZ", // ISO 8601 with milliseconds and UTC offset
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZ", // ISO 8601 with microseconds and UTC offset
    "YYYY-MM-DD HH:mm:ss", // yyyy-mm-dd hh:mm:ss
  ];

  // Check each format and log the result for debugging
  for (let format of formatsToCheck) {
    if (moment(dateString, format, true).isValid()) {
      // console.log(`Matched format: ${format}`); // Log matched format
      return format; // Return the first valid format found
    } else {
      // console.log(`Format did not match: ${format}`); // Log failed format
    }
  }

  // console.log("No matching format found"); // Log if no format matched
  return null; // Return null if no valid format is found
}

export const formatToDDMMYYYY = (date) => {
  if (!date) return null;
  const inferredFormat = inferDateFormat(date);
  if (!inferredFormat) return null;
  return moment(date, inferredFormat).format("DD-MM-YYYY");
};


export const formatToDOMMYYYY = (date, time = false) => {
  if (!date) return null;
  if (time) {
    return  moment(date, inferDateFormat(date)).format("DD/MM/YYYY h:mm a");  
  }
  return  moment(date, inferDateFormat(date)).format("Do MMM YYYY");
};
