
import axios from 'axios';
import { ErrorLogger, FailedPopup, clearSessionStorage, customLogger, getSessionStorage, removeSessionStorage, resetNavigation, setSessionStorage, } from '../Utils/Utils';
import { Modal } from '@arco-design/web-react';



export const GET_API = async (path, headers = '', params) => {

    var config = { method: "get", url: global.baseUrl + path, headers: { Authorization: headers } };
    var config = headers ? { method: "get", url: global.baseUrl + path, headers: { Authorization: headers } } : { method: "get", url: global.baseUrl + path, };
    // 
    let response = await axios(config).then(function (response) {
        // customLogger("GET API response", response);

        return response;
    })
        .catch(function (error) {

            //customLogger("GET API error", error);
            if (error.response) {
                return error.response;
            } else {

                return { status: 503, message: "NetWork Error !" };
            }
        });

    return ResponseValidation(response, config);
};
export const DELETE_API = async (path, headers = '', params) => {


    var config = headers ? { method: "delete", url: global.baseUrl + path, headers: { Authorization: headers } } : { method: "delete", url: global.baseUrl + path };

    // 
    let response = await axios(config).then(function (response) {
        //customLogger("delete API response", response);

        return response;
    })
        .catch(function (error) {

            //customLogger("delete API error", error);
            if (error.response) {
                return error.response;
            } else {

                return { status: 503, message: "NetWork Error !" };
            }
        });

    return ResponseValidation(response, config);
};



// axios Post rqst


export const POST_API = async (data, path, headers = '') => {
    var config = headers ? {
        method: 'post',
        url: global.baseUrl + path,
        headers: {
            'Authorization': headers
        },
        data: data
    } : {
        method: 'post',
        url: global.baseUrl + path,
        data: data
    };

    customLogger({ config })

    let response = await axios(config).then(function (response) {
        //customLogger("POST API response", response);
        return response;
    })

        .catch(function (error) {
            //customLogger({ error })
            if (error.response) {
                return error.response;
            } else {
                return { status: 503, message: "NetWork Error !", config };
            }
        });
    return ResponseValidation(response, config);
};


//api response validate function
let count404Request = 0
export const ResponseValidation = async (response, config, navigations) => {
    // const UserData = AuthContext.displayName;
    //customLogger({ response })
    let status_code = null;
    let response_data = null;
    let message = null;

    if (response) {
        if (response.status) {
            // //customLogger("res status", response.status);
            if (response.status === 200) {
                message = "Success";
                status_code = response.status;
                response_data = response.data;
            } else if ([401].includes(response.status)) {
                status_code = response.status;
                response_data = response.data;
                FailedPopup('Session Expired', async () => {
                    const sessionData = getSessionStorage("userdetails");
                    if (sessionData?.redirect_from) {
                        const a = document.createElement("a");
                        a.href = `${sessionData?.redirect_from}?payment_result=0&error=Session Expired`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        await clearSessionStorage();
                        resetNavigation('/login');
                        Modal.destroyAll()
                    }
                });



            } else if (response.status === 400) {
                // //customLogger({ response });
                message = response.data.message;
                status_code = response.status;
                response_data = response.data;
            }
            else if (response.status === 404) {
                message = response.data.message || "Page Not found";
                status_code = response.status;
                response_data = 0;
                // //customLogger(JSON.stringify(response_data));
            }
            else if (response.status === 406) {
                message = "No visible text in page";
                status_code = response.status;
                response_data = 0;
                // //customLogger(JSON.stringify(response_data));
            }
            else if (response.status === 403) {
                message = response.data.message;
                status_code = response.status;
                response_data = 0;
                // //customLogger(JSON.stringify(response_data));
            }
            else if (response.status === 500) {
                message = response.data.message;
                status_code = response.status;
                response_data = 0;

            } else if (response.status === 503) {
                //message = "Seems like server is not running";
                status_code = response.status;
                response_data = 0;
                if (count404Request==0) {
                    message = "Make sure you are connected to Wi-Fi or your cellular network.";
                  }
          
                  setTimeout(() => {
                    count404Request =0
                  }, 150);
                    
                  count404Request++
                  // increment the value 
              

            } else if (response.status === 422) {
                message = response.data.message;
                status_code = response.status;
                response_data = response.data;

            }
            else {
                message = "Unhandled Condition met";
                response_data = 0;


            }
            ErrorLogger(status_code, response?.config?.url, message)
            return { response_data, message, status_code };
        } else {
            message = "unexpected response got";
            // //customLogger("Response validation - No response status", response);
            ErrorLogger(status_code, response.config.url, message)
            return { message, status_code };
        }
    }
};
