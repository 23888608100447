import React, { useContext, useEffect, useState } from 'react'
import { WalletOutline,NewspaperOutline, ReaderOutline, PaperPlaneOutline, DocumentTextOutline, CardOutline, AlbumsOutline, CalculatorOutline, MailOpenOutline, PersonOutline, SettingsOutline, ExitOutline, RefreshOutline, CashOutline } from 'react-ionicons'
import { useNavigate } from 'react-router-dom'
import { ContextAPI } from '../../common/Context/ContextApi'
import { ManageApis } from '../../common/APIS/ManageApis'
import { customLogger } from '../../common/Utils/Utils'
import Icon from '@mdi/react';
import { mdiGold } from '@mdi/js';



function Dashboard() {
	const navigate = useNavigate()
	
	

	
  return (
	<>
		<div className='p-4 dashboard overflow-auto custom_scroll1'>	
			<div>
				<div className='pb-3'>
					<h2 className='fw_600 h3 text_master'>Welcome to KSFE</h2>
					<p className='text_gray7'>The Kerala State Financial Enterprises Limited</p>
				</div>
				<div className='mb-2'>
					<h4 className='font_16 fw_600 text_master mb-3'>Quick Links</h4>
					<div className='row'>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/quickpay')}}>
								<WalletOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Quick Pay</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/mychits')}} >
								<NewspaperOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>My Chits</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/myloans')}} >
								<CashOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>My Loans</span>
							</div>
						</div>
            {global.isGoldODEnabled &&
								<div className='col-6 col-sm-8 max_w_180x mb-3'>
								<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/goldod')}}>
									{/* <RadioButtonOnOutline height="26px" width="26px"  color={''}  className="text_master" />
										*/}
										<div>
										<Icon path={mdiGold}  height="26px" width="26px" className='text_master' />
										</div>
									<span className='fw_600 pt-3'>My Gold OD</span>
								</div>
							</div>					
						}
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/paymenthistory')}}>
								<ReaderOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Transaction</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/myrequest')}}>
								<PaperPlaneOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>My Request</span>
							</div>
						</div>
						
					</div>
				</div>
				<div className='mb-2'>
					<h4 className='font_16 fw_600 text_master mb-3'>Account Overview</h4>
					<div className='row'>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/myaccount')}}>
								<CardOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>My Account</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/auctiondetails')}}>
								<DocumentTextOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Auction</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/newchits')}}>
								<AlbumsOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>New Chits</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/emicalculator')}}>
								<CalculatorOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>EMI Calculator</span>
							</div>
						</div>
						<div className='col-6 col-sm-8 max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/feedback-complaint')}}>
								<MailOpenOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Feedback</span>
							</div>
						</div>
					</div>
				</div>
				<div className='mb-2'>
					<h4 className='font_16 fw_600 text_master mb-3'>Profile & Settings</h4>
					<div className='row'>
						<div className='col-6 col-sm max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/profile')}}>
								<PersonOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Profile</span>
							</div>
						</div>
						{/* <div className='col-6 col-sm max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/quickpay')}}>
								<SettingsOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Settings</span>
							</div>
						</div> */}
						<div className='col-6 col-sm max_w_180x mb-3'>
							<div className='bg-white br_10 d-grid px-3 py-4 text-center h-100 hover cursor-pointer' onClick={() => {navigate('/logout')}}>
								<ExitOutline height="26px" width="26px"  color={''}  className="text_master" />
								<span className='fw_600 pt-3'>Logout</span>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</>
  )
}

export default Dashboard