import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { PreventNonNumericChar, customLogger, getAge, getDateFromToday, isValidPanCardNo, lengthValidation, preventFirstSpace, preventNumSpecialChars, preventSpecialChars, preventSpecialCharsandChar, preventSpecialCharsandCharAllowSlash, preventSpecialCharsandNum, removeLeadingZerosRegex, validateAlphanumeric, validateEmail, validateLength, validatePassword } from '../../common/Utils/Utils'
import { DatePicker, Input, Select } from '@arco-design/web-react'
import dayjs from 'dayjs'
import moment from 'moment';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const { RangePicker } = DatePicker;
const Option = Select.Option
const InputSearch = Input.Search;

const InputField = ({ type, label, placeholder, required, onchange, value, onblur, onfocus,
    disabled, options, name, errorMessage, seterrorMessage, marginBottom, onClear, maxLen, handleSubmit,
    helperText, minValue, maxValue, handleDuration, durationChange, rows, className, disabledDate, onselect, 
    searchable, dateFormat, nonCaseRes, inputRef,onsearchSearchinputchange,searchInputvalue,validation

}) => {
    
    

    const [showpassword, setshowpassword] = useState(false)
    // const [errorMessage, seterrorMessage] = useState(error)

    const [Value, setValue] = useState(value)

    useEffect(() => {
        const iconClick = document.querySelectorAll('.arco-picker-header-icon'); // Replace with the actual class name
        iconClick.forEach((item) => {
            item.addEventListener('click', monthNumbertoName);
        });
        // Clean up the event listeners when the component unmounts
        return () => {
            iconClick.forEach((item) => {
                item.removeEventListener('click', monthNumbertoName);
          });
        };
    }, []);



    useEffect(() => {
        return () => {
            setshowpassword(false)
        }
    }, [])
    useEffect(() => {
        // console.log({Value});
        setValue(value)
    }, [value])

    const monthNumbertoName = () => {
        setTimeout(() => {
            const monthNames = [
                'Jan', 'Feb', 'Mar', 'Apl', 'May', 'Jun',
                'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
              ];
            const monthNumberArray = [
                '01', '02', '03', '04', '05', '06',
                '07', '08', '09', '10', '11', '12'
            ];
            const elements = document.getElementsByClassName('arco-picker-header-label');
            const elementsArray = [...elements];
            elementsArray?.map(item => {
                let  monthNumber = item.innerHTML
                const isNumberInArray = monthNumberArray.includes(monthNumber);
                if(isNumberInArray){
                    item.innerHTML = monthNames[monthNumber - 1]
                    //item.innerHTML = moment(item.innerHTML, "MM").format("MMM")
                }
            })
          }, 10);
        
    }

    const handleChange = (event, rec) => {
        if (type.includes('date-range')) {
            // seterrorMessage(name, validateAlphanumeric(event))
            onchange(event, name)
            setValue(event)
        } else if (type.includes('date')) {
            if (name?.includes('dob')) {
                if(event) {
                let age = getAge(event)
                seterrorMessage(name, (age < 18 ? "Age should be more than 18 years" : ""))}
                setValue(event)
                onchange(event, name)
            } else {
                seterrorMessage(name, lengthValidation(JSON.stringify(event)))
                setValue(event)
                onchange(event, name)
            }
        } else if (type.includes('select')) {
            const { _key } = rec || {}
            if (_key) {
                onchange(_key, name)
                setValue(_key)
            } else {
                onchange('', name)
                setValue('')
            }
            // if (selectedItem) {
            //     selectedItem(item.id)
            //     console.log('selected', item.id)
            // }
            // if (selectedItemString) {
            //     selectedItemString(item.title)
            // }



        } else if (searchable) {
           const val = validation? validation(event):event 
            setValue(val)
            onchange(val, name)
        } else {
            const { value, name } = event?.target
            switch (type) {
                case 'username':
                    let val =""
                    if(nonCaseRes){
                        val = value
                    }else{
                        val = value.toLowerCase()
                    }
                    
                    let filteredValue = preventSpecialChars(validateLength(val, 15))
                    seterrorMessage(name, validateAlphanumeric(filteredValue, 6))
                    onchange(filteredValue, name)
                    setValue(filteredValue)
                    break;
                case 'password':
                    let filteredpassword = validateLength(value, 12)
                    if (!name.includes('confirmPassword') || !value.length) {
                        seterrorMessage(name, validatePassword(filteredpassword))
                    }
                    setValue(filteredpassword)
                    onchange(filteredpassword, name)
                    break;
                        
                case 'email':
                    let emailValue = validateLength(value, 50)
                    if (emailValue?.length) {
                        seterrorMessage(name, validateEmail(emailValue))
                    } else {
                        // if the length of email is false and field is reuired the error will throw 
                        if (required) {
                            seterrorMessage(name, validateEmail(emailValue))
                        } else {
                            seterrorMessage(name, '')
                        }
                    }
                    setValue(emailValue)
                    onchange(emailValue, name)
                    break;
                default:
                    if (['first_name', 'last_name',].includes(name)) {
                        let filteredfirstname = preventNumSpecialChars(validateLength(value, 20))
                        seterrorMessage(name, lengthValidation(filteredfirstname))
                        setValue(filteredfirstname)
                        onchange(filteredfirstname, name)
                    } else if (['mobile'].includes(type)) {
                        let filteredmobile = preventSpecialChars(validateLength(value, maxLen || 10))
                        seterrorMessage(name, lengthValidation(filteredmobile, maxLen || 10))
                        setValue(filteredmobile)
                        onchange(filteredmobile, name)
                    } else if (['guardian_name'].includes(name)) {
                        let filteredGuardianName = preventSpecialCharsandNum(validateLength(value, 20))
                        seterrorMessage(name, lengthValidation(filteredGuardianName))
                        setValue(filteredGuardianName)
                        onchange(filteredGuardianName, name)
                    } else if (['chitty_no'].includes(name)) {
                        let filteredChittyNo = preventSpecialCharsandCharAllowSlash(validateLength(value, maxLen || 8))
                        seterrorMessage(name, lengthValidation(filteredChittyNo))
                        setValue(filteredChittyNo)
                        onchange(filteredChittyNo, name)
                    } else if (['chitty_reg_no'].includes(name)) {
                        let filteredChittyNo = validateLength(value, 15)
                        setValue(filteredChittyNo)
                        onchange(filteredChittyNo, name)
                    } else if (['chittal_no'].includes(name)) {
                        let filteredChittyNo = preventSpecialCharsandChar(validateLength(value, 5))
                        seterrorMessage(name, lengthValidation(filteredChittyNo))
                        setValue(filteredChittyNo)
                        onchange(filteredChittyNo, name)
                    } else if (['chittal_name'].includes(name)) {
                        const newValue = value.slice(0, 25);
                        setValue(newValue)
                        onchange(newValue, name)
                    } else if (['panNo'].includes(name)) {
                        
                        let newValue = validateLength(value.toUpperCase(), 10)
                        setValue(newValue)
                        onchange(newValue)
                        if (!isValidPanCardNo(newValue)) {
                            seterrorMessage(name, "Enter a valid PAN Number")
                        
                        }
                        else {
                            seterrorMessage(name, "")
                        }
                    } else if (['amount'].includes(name)) {
                        if(value && typeof parseInt(value) == "number" && !parseInt(value)) return
                        let filteredmobile = preventSpecialChars(removeLeadingZerosRegex(validateLength(value, maxLen || 10)))
                        // seterrorMessage(name, lengthValidation(filteredmobile, maxLen || 10))
                        setValue(filteredmobile)
                        onchange(filteredmobile, name)
                    } else if (['sala','denomination'].includes(name)) {
                        if(value.length<10)
                            setValue(value)
                        onchange(value, name)
                    }
                    else {
                        customLogger({ value, name })
                        // let filteredmobile = preventSpecialChars(validateLength(value, 10))
                        if (required) {
                            seterrorMessage(name, lengthValidation(value))
                        }
                        setValue(value)
                        onchange(value, name)
                    }
                    break;
            }
        }
    }

    const handleDateRangeClear = () => {
        setValue('')
        onClear()
    }
    const handleClickEyeButton = () => setshowpassword(!showpassword)

    const handleKeyDown = (e) => {
        preventFirstSpace(e, Value)
        if (['number', 'mobile'].includes(type)) {
            PreventNonNumericChar(e)
        }
    }

    const handleDisabledDate = (current)=>{
        if (name.includes('dob')) {
            let dateOf18Yrs = getDateFromToday(18)
            let dateOf100YrsAgo = getDateFromToday(100); // Uplimit set as 100year #cp-39
            //return current.isAfter(dayjs(dateOf18Yrs))
           return current.isAfter(dayjs(dateOf18Yrs)) || current.isBefore(dayjs(dateOf100YrsAgo));
        }else return current.isAfter(dayjs())
    }

    if (type.includes('select')) {
        return (
            <div className={!marginBottom ? 'mb-3 w-100 ' : 'mb-0 w-100 '}>
                <label className="mb-0 pb_2x text_master fw_500 font_13">
                    {label}
                    {required &&
                        <span className="text-danger">*</span>
                    }
                </label>
                <div className='arco_select'>
                    <Select placeholder={placeholder} showSearch={{ retainInputValue: true }} style={{ width: 200 }}  onInputValueChange={onsearchSearchinputchange} inputValue={searchInputvalue} allowClear className={`rounded font_12 ${errorMessage ? 'border-danger' : ''}`} onChange={handleChange} onBlur={onblur} onFocus={onfocus} onClear={onClear} value={options?.find(itm => itm.key == Value || itm.value == Value)?.key} name={name} disabled={disabled}>
                        {/* <Option disabled selected>{`Choose ${label}`}</Option> */}
                        {options.map(item =>
                            <Option value={item.key} key={item.value}>{item.key}</Option>
                        )}
                    </Select>
                </div>
                {errorMessage &&
                    <span className="text-danger font_10">{errorMessage}</span>
                }
                {helperText &&
                    <div className="font_10 pt_2x">{helperText}</div>
                }
            </div>
        )
    } else {
        return (
            <div className={!marginBottom ? 'mb-3 w-100' : 'mb-0 w-100'}>
                {type.includes('date-range') ?
                    <>
                        <label className="mb-0 pb_2x text_master fw_500 font_13">
                            {label}
                            {required &&
                                <span className="text-danger">*</span>
                            }
                        </label>
                        <div className='arco_rangepicker'>
                            <RangePicker mode='date' onChange={handleChange} allowClear onSelect={onselect} onClear={handleDateRangeClear} value={value}
                                format={dateFormat? dateFormat :'DD-MM-YYYY'}
                                disabledDate={(current) => current && current > moment().endOf('day')}
                                // disabledDate={disabledDate && disabledDate}
                                panelRender={(panelNode) => {
                                    monthNumbertoName()
                                    return (
                                        <>
                                          {panelNode}
                                        </>
                                      );
                                  }}
                            />
                        </div>
                        {errorMessage &&
                            <span className="text-danger font_10">{errorMessage}</span>
                        }
                        {helperText &&
                            <div className="font_10 pt_2x">{helperText}</div>
                        }

                    </>
                    :
                    type.includes('slider') ?
                        <>

                            <div className="d-flex justify-content-between">
                                <label className="mb-0 pb_2x text_master fw_500 font_13">
                                    {label}
                                    {required &&
                                        <span className="text-danger">*</span>
                                    }
                                </label>
                                {durationChange &&
                                    <span>{value}</span>
                                }
                            </div>
                            <div className='meterialUI'>
                            <Box sx={{ }}>
                                <Slider
                                    size="small"
                                    value={value}
                                    tooltipVisible={true}
                                    max={maxValue}
                                    min={minValue} 
                                    onChange={value =>handleDuration(value.target.value.toFixed())}
                                    valueLabelDisplay="auto"
                                />
                                {/* <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" /> */}
                                </Box>
                                {/* <Slider value={value} tooltipVisible={true} max={maxValue} min={minValue} onChange={value => handleDuration(value.toFixed())} /> */}
                            </div>
                        </>

                        :
                        <>  {label &&
                            <label className="mb-0 pb_2x text_master fw_500 font_13">
                                {label}
                                {required &&
                                    <span className="text-danger">*</span>
                                }
                            </label>
                        }

                            {type.includes('password') ?
                                <div className="input_withicon end">
                                    <input
                                        type={type.includes('password') ? showpassword ? 'text' : 'password' : type}
                                        className={`form-control rounded font_12 ${errorMessage ? 'border-danger' : ''}`}

                                        placeholder={placeholder || `Enter ${label}`}
                                        onChange={handleChange}
                                        // onBlur={onblur}
                                        // onFocus={onfocus}
                                        value={Value}
                                        disabled={disabled}
                                        name={name}
                                    />
                                    <i className={`far fa-${!showpassword ? 'eye-slash' : 'eye'}  icn p-2 text_gray7`} onClick={handleClickEyeButton}></i>
                                </div>
                                :
                                type.includes('date') ?
                                
                                    <div className='arco_datepicker'>
                                        <DatePicker
                                            allowClear
                                            className={`w-100 rounded font_12 ${errorMessage ? 'border-danger' : ''}`}
                                            onChange={handleChange}
                                            disabledDate={handleDisabledDate}
                                            format={dateFormat? dateFormat :'DD-MM-YYYY'}
                                            defaultPickerValue={name.includes('dob')?getDateFromToday(18):new Date()}
                                            value={Value}
                                            showNowBtn={false}
                                             panelRender={(panelNode) => {
                                                monthNumbertoName()
                                                return (
                                                    <>
                                                    {panelNode}
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                    :
                                    type.includes('textarea') ?
                                        <textarea
                                            rows={rows}
                                            className={`form-control rounded font_12 ${errorMessage ? 'border-danger' : ''}`}
                                            placeholder={placeholder || `Enter ${label}`}
                                            onChange={handleChange}
                                            onFocus={onfocus}
                                            value={value}
                                            disabled={disabled}
                                            name={name}
                                        >
                                        </textarea>
                                        :
                                        searchable ?
                                            <div className='arco_inputsearch'>
                                                <InputSearch
                                                    searchButton={searchable}
                                                    allowClear
                                                    onClear={onClear}
                                                    onSearch={handleSubmit}
                                                    type={type.includes('password') ? showpassword ? 'text' : 'password' : type.includes('mobile') ? 'number' : type.includes('username') ? 'text' : type}
                                                    className={`${className ? className : ''} rounded font_12 ${errorMessage ? 'border-danger' : ''}`}
                                                    onWheel={e => type.includes('mobile') ? e.target.blur() : ''}
                                                    placeholder={placeholder || `Enter ${label ? label : ''}`}
                                                    onChange={handleChange}
                                                    // onBlur={onblur}
                                                    onFocus={onfocus}
                                                    value={Value}
                                                    disabled={disabled}
                                                    name={name}
                                                    onKeyDown={handleKeyDown}
                                                // style={{ width: 350 }}
                                                />
                                            </div>
                                            :

                                            <input
                                                type={type.includes('password') ? showpassword ? 'text' : 'password' : type.includes('mobile') ? 'number' : type.includes('username') ? 'text' : type}
                                                className={`${className ? className : ''} form-control rounded font_12 ${errorMessage ? 'border-danger' : ''}`}
                                                onWheel={e => type.includes('mobile') ? e.target.blur() : ''}
                                                placeholder={placeholder || `Enter ${label ? label : ''}`}
                                                onChange={handleChange}
                                                // onBlur={onblur}
                                                onFocus={onfocus}
                                                value={Value}
                                                disabled={disabled}
                                                name={name}
                                                maxLength={maxLen}
                                                onKeyDown={handleKeyDown}
                                                ref={inputRef}
                                            />

                            }
                            {errorMessage &&
                                <div className="text-danger font_10 pt_2x">{errorMessage}</div>
                            }
                            {helperText &&
                                <div className="font_10 pt_2x">{helperText}</div>
                            }
                        </>
                }

            </div>
        )
    }

}

export default InputField
