import { Message, Modal, Skeleton } from "@arco-design/web-react"
import moment from "moment"

const BlankValidation = (name, item) => {
    // alert('1')
    customLogger('blank validate', name, item);
    if (item === "" || item === null) {
        // customLogger(name.replaceAll('_', ' ') + ' cannot be empty')
        customLogger(name.replaceAll('_', ' ') + ' cannot be empty')
        //   showMessage(name + " cannot blank! ", "warning", "Blank Validation Failed", "top", "top-right", 5000)
        return 0
    } else {
        //   showMessage(name + " validation passed! ", "success", "Validation Success", "bottom", "bottom-left", 2000)
        return 1
    }
}

export const PreventNonNumericChar = (e) => {
    let array = ['e', '+', '-', '.', 'E']

    if (array.includes(e.key)) {
        e.preventDefault()
    }

}
export function downloadBase64PDF(base64String, fileName) {
    try {
        // Decode the base64 string to binary data
        const binaryData = atob(base64String);

        // Create a Uint8Array from the binary data
        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob object from the binary data
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

        // Create a URL for the Blob object
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element for download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;

        // Trigger a click event to initiate the download
        downloadLink.click();

        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(blobUrl);
    } catch (err) {
        console.log(err);
        return false
    }
}
export const FormValidation = (data, validatingData) => {
    // customLogger("validating  Data ", data)
    let validation = 1;
    let failedItems = []
    for (let i = 0; i < validatingData.length; i++) {
        if (BlankValidation(validatingData[i], data[validatingData[i]])) {
            customLogger("Validation passed for ", validatingData[i])
        } else {
            failedItems.push(validatingData[i])
            validation = 0;
            // break;
        }
    }

    return { validation, failedItems };
}
// Remove special characters from numbers
export const sanitizedText = text => text.replace(/[^0-9]/g, '');

export const preventSpecialChars = value => value?.replace(/[^a-zA-Z0-9]/g, '')
export const preventSpecialCharsandNum = value => value.replace(/[^a-zA-Z\s]/g, '')
export const preventSpecialCharsandChar = value => value.replace(/[^0-9]/g, '')
export const preventSpecialCharsandCharAllowSlash = value => value.replace(/[^0-9\/]/g, '')
export const removeLeadingZerosRegex = str => str.replace(/^0+(?=\d)/, '')

export const isValidPanCardNo = (panCardNo) => {
    // Regex to check valid
    // PAN Number
    var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    return regpan.test(panCardNo);
};
export function getDateFromToday(diff = 18) {
    const today = new Date();
    const PastDate = new Date(today.getFullYear() - diff, today.getMonth(), today.getDate());
    return PastDate;
}
export function validateAlphanumeric(str, minLen, maxLen = false) {
    const alphanumericPattern = /^[a-zA-Z0-9]+$/; // regular expression pattern for alphanumeric characters only
    const len = str.length;
    let text = "";
    if (len < minLen) {
        text = "Required minimum 6 characters";
    }
    else if (!alphanumericPattern.test(str)) {
        text = "Username must be alphanumeric";
    }
    return text;
}

export const validatePassword = (text) => {
    let error = ''

    let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*[\]{}()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

    if (!text) {
        error = "Password cannot be blank.";
    } else if (!text.match(passwordRegex)) {
        error = "Password must be a minimum of 8 characters and include special characters, uppercase and lowercase letters, and numbers.";
    }
    return error
}


export const splitCamelCase = (data) => {
    let outdata = data.replace(/([a-z])([A-Z])/g, "$1 $2");
    let str = firstCamelCase(outdata);
    return str;
};
export const firstCamelCase = (data) => {
    customLogger(data)
    let modifiedString = data?.replace(/([A-Z])/g, ' $1');
    modifiedString = modifiedString?.charAt(0).toUpperCase() + modifiedString?.slice(1);
    return modifiedString
    // data.charAt(0).toUpperCase() + data.slice(1);
}

export const validateEmail = (text) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailRegex.test(text)) return ''
    else return 'Enter valid email'
};

export const validateLength = (text, length = 5) => text.slice(0, length).replace(/\s+/g, ' ')
export const preventNumSpecialChars = value => value.replace(/[^a-zA-Z\s]/g, '')
export const lengthValidation = (text, length) => {
    if (length) {
        return text.length ? text.length != length ? `Required minimum ${length}` : '' : 'Field is required'
    } else {
        return text?.length ? '' : 'Field is required'

    }
}

export function sanitizePhoneNumber(input) {
    // Remove any non-digit characters from the input

    return input.target.value = input.target.value?.replace(/\D/g, "");
}

export const preventFirstSpace = (e, value) => {
    if (e.key === ' ' && value.length === 0) {
        e.preventDefault(); // Prevent adding a space as the first character
    }
}
export function preventFirstLetterZero(input) {
    // Convert input to a string if it isn't one
    const str = String(input);
    // Check if the first character is "0" and remove it if necessary
    return str.length > 1 && str.startsWith("0") && !str.startsWith("0.")
      ? str.slice(1)
      : str;
  }

export function getAge(DOB) {
    var today = new Date();
    const parts = DOB.split('-'); // Split the string into parts
    const formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

    var birthDate = new Date(formattedDateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const formatDate = (date, fromformat, format) => {
    // let newDate = new Date(date)
    if (date) {
        if (fromformat) {
            const momentObj = moment(date, fromformat);
            const momentString = momentObj.format(format);
            customLogger({ format }, { momentString });
            return momentString;
        } else {
            const momentObj = moment(date);
            const momentString = momentObj.format(format);
            customLogger({ format }, { momentString });
            return momentString;
        }

    } else return ''

};

export const successPopup = (res) => {
    // Message.success(message)
    if (res?.response_data?.message) Message.success(res.response_data.message)
    else if (res?.message) Message.success(res.message)
    else Message.success(res)
}
// export const FailedPopup = (res) => {
//     if (res?.response_data?.message) Message.error(res.response_data.message)
//     else if (res?.message) Message.error(res.message)
//     else Message.error(res)
// }
export const FailedPopup = (res, onOk) => {
    res = JSON.stringify(res)
    if (res.includes('401')) {
        return
    } else {
        res = JSON.parse(res)
        Modal.error({
            title: 'Failed',
            content: (res?.response_data?.message)
                ? res.response_data.message
                : (res?.message)
                    ? res.message
                    : res,
            okButtonProps: {
                status: 'master',
            },
            okText: 'OK',
            wrapClassName: 'arco_modal_wrap',
            maskClosable: false,
            escToExit: false,
            onOk: onOk ? onOk : '',
        });
    }
}


export const ConfirmSuccessPopup = (res, onOk, type, onCancel, okText, cancelText) => {

    const modalConfig = {
        title: '',
        icon: ' ',
        content: (res?.response_data?.message) ? res.response_data.message : (res?.message) ? res.message : res,
        okButtonProps: {
            status: 'master',
        },
        onOk: onOk ? onOk : '',
        onCancel: onCancel ? onCancel : '',
        cancelText: cancelText ? cancelText : 'Cancel',
        okText: okText ? okText : 'OK',
        wrapClassName: 'arco_modal_wrap',
        maskClosable: false,
        escToExit: false,
    };

    if (type) {
        switch (type) {
            case 'confirm':
                Modal.confirm(modalConfig);
                break;
            default:
                Modal.info(modalConfig);
                break;
        }
    } else {
        Modal.info(modalConfig);
    }
}
export const JSONStringify = (value) => JSON.stringify(value)
export const JSONParse = (value) => isValidJSON(value) ? JSON.parse(value) : ''

export function isValidJSON(jsonString) {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

export const getMonthsRange = (range = 3, startdate, enddate) => {
    var today = startdate || enddate || new Date();

    if (startdate) {
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        var endDate = new Date(today.getFullYear(), today.getMonth() + range, today.getDate());
    } else {
        var startDate = new Date(today.getFullYear(), today.getMonth() - range, today.getDate());
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    return {
        startDate,
        endDate
    };
}
// sessions 
export const SessionStorage = async (key, value) => {
    try {
        await localStorage.setItem(key, value)
        const currentValue = localStorage.getItem(key)
        return currentValue === value
    } catch (err) {
        return err
    }
}
export const setSessionStorage = async (key, value) => {
    try {
        await localStorage.setItem(key, value)
        const currentValue = localStorage.getItem(key)
        return currentValue === value
    } catch (err) {
        return err
    }
}
export const getSessionStorage = (key) => {
    const data = localStorage.getItem(key);

    if (isValidJson(data)) {
        return JSON.parse(data)
    } else {
        return data
    }
}
function isValidJson(jsonString) {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

export const objectToString = (obj) => {
    const result = {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = String(obj[key]);
        }
    }

    return result;
};

export const removeSessionStorage = (key, value) => {
    try {
        localStorage.removeItem(key)
        return true
    } catch (err) {
        return err
    }

}
export const clearSessionStorage = () => {
    try {
        return localStorage.clear()
    } catch (err) {
        return err
    }
}

export const ErrorLogger = (status_code, endpoint, message) => {
    // customLogger("error log----------->", status_code, endpoint, message);
};
export const resetNavigation = (path) => window.location.replace(path);

export const dataTableInitialLoader = (columns) => {
    let initialLoader = []
    let obj = {}
    for (let i = 0; i < 5; i++) {
        for (const j of columns) {
            obj[j.dataIndex] = <Skeleton style={{ height: '20px' }} text={{ rows: 1 }} />
        }
        initialLoader.push(obj)
    }
    return initialLoader
}
export function createNewObjectsWithSelectedKeys(dataArray, selectedKeys) {
    const newArray = [];

    for (const oldObj of dataArray) {
        const newObj = {};

        for (const key of selectedKeys) {
            if (oldObj.hasOwnProperty(key)) {
                newObj[key] = oldObj[key];
            }
        }

        for (const item of oldObj.chittals) {
            for (const propKey in item) {
                if (selectedKeys.includes(propKey)) {
                    newObj[propKey] = item[propKey];
                }
            }
        }

        newArray.push(newObj);
    }

    return newArray;
}

export const generateNonce = (length) => {
    const characters = '0123456789abcdef';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}

// common function for console log
export const customLogger = (...message) => console.log(message);

export const findMinutesDiff = (date1, date2) => {
    const timestamp1 = date1.getTime()
    const timestamp2 = date2.getTime()
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(timestamp1 - timestamp2)

    // Convert milliseconds to minutes (1 minute = 60,000 milliseconds)
    const minuteDifference = Math.floor(timeDifference / 60000);

    return minuteDifference
}

export const getTimeAgo = (timestamp) => {
    const currentTime = moment();
    const timeDifference = moment.duration(currentTime.diff(moment(timestamp)));
    const minutes = timeDifference.minutes();
    const seconds = timeDifference.seconds();
    let date = moment(timestamp).format();
    return moment(date).startOf('minutes').fromNow();

    if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}

export function capitalizeFirstLetter(str) {
    // console.log({str})
    str = str?.toLowerCase()
    return str?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

}

export function loadScript(url) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = url;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export function getGoldODBankVerificationStatusMessage(status) {
    switch (status) {
      case 1:
        return "The request is still pending.";
      case 4:
        return "The request has been rejected by the customer.";
      case 5:
        return "The request has been rejected by the branch.";
      case 6:
        return "The request is still pending.";
      case 7:
        return "The request is currently inactive.";
      case 8:
        return "The request has been rejected.";
      default:
        return `Unknown status passed : ${status} `;
    }
  }
  

export const replaceUnderScoreAndCapitialize =(value) =>{
if (value) {
   return capitalizeFirstLetter(value.replaceAll('_',' '))
}
}

export const sortByDateDescending = (list, dateField) => {
    return list.sort((a, b) => {
        const dateA = new Date(a[dateField].replace(/\//g, '-').split('-').reverse().join('-'));
        const dateB = new Date(b[dateField].replace(/\//g, '-').split('-').reverse().join('-'));
        return dateB - dateA; // Sort in descending order
    });
};