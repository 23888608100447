export const fedBankPG = (data, callBack) => {
    if (!data || !data.consumerData) {
        callBack("Invalid gateway details passed ")
      return;
    }
  
    // Load jQuery and Paynimo Checkout script dynamically
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {

              // Get all elements with both classes 'fade' and 'modal'
      const elements = document.querySelectorAll('.fade.modal');

      // Loop through each element and remove the 'tabindex' attribute
      elements.forEach(function (element) {
        element.removeAttribute('tabindex');
      });

        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };
  
    const handleResponse = (res) => {
        if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0300') {
            callBack(res)
            // success block
        } else if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0398') {
            callBack(res)
            // initiated block
        } else {
            callBack(0)
            // error block
        }
    };
    const { buttonColorCode1, buttonColorCode2, primaryColorCode, secondaryColorCode } = data.consumerData.customStyle
  
    const handle_fedBankPG = () => {
      const configJson = {
        ...data,
        consumerData: {
          ...data.consumerData,
          responseHandler: handleResponse,
          customStyle: {
            'PRIMARY_COLOR_CODE': primaryColorCode,   //merchant primary color code
            'SECONDARY_COLOR_CODE': secondaryColorCode,   //provide merchant's suitable color code
            'BUTTON_COLOR_CODE_1': buttonColorCode1,   //merchant's button background color code
            'BUTTON_COLOR_CODE_2': buttonColorCode2   //provide merchant's suitable color code for button text
          }
        },
      };
  
      if (window.$ && window.$.pnCheckout) {
        window.$.pnCheckout(configJson);
        if (configJson.features.enableNewWindowFlow) {
          window.pnCheckoutShared.openNewWindow();
        }
      } else {
          console.error('Paynimo Checkout script not loaded.');
        
      }
    };
  
    
    const init = async () => {
      try {
        await loadScript(data.consumerData.jqueryUrl);
        await loadScript(data.consumerData.checkoutUrl);
        handle_fedBankPG(); 
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };
  
    // Call init() to start the process
    init();
  };

  