import BackBtn from "../../../Components/BackBtn/BackBtn";
import { useContext, useEffect, useMemo, useState } from "react";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { FailedPopup } from "../../../common/Utils/Utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ManageApis } from "../../../common/APIS/ManageApis";
import Layout from "../../../Components/Layout/Layout";
import PopupModal from "../../../Components/PopupModal";
import ODLiveSection from "../GoldOdComponents/ODLiveSection";
import ODPendingSection from "../GoldOdComponents/ODPendingSection";
import KeyValue from "../../../Components/KeyValue/KeyValue";
import OrnamentDetailsList from "../../../Components/OdOrnaments/GoldOdOrnaments";

const GoldOdDetails = () => {
  const { state } = useLocation();
  const { accountId } = state || {};
  const navigation = useNavigate();
  const { token } = useContext(ContextAPI);
  const [isChecked, setIsChecked] = useState(false);
  const [AccountDetails, setAccountDetails] = useState({});
  const [loading, setloading] = useState(false);
  const [InputData, setInputData] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [ornamnentDetails, setOrnamnentDetails] = useState([]);
  const [ModalProps, setModalProps] = useState({
    open: false,
    title: "",
    message: "",
    props: [],
  });

  useEffect(() => {
    handleGetAccountDetails();
  }, []);

  const AccountDetailsItems = [
    { key: "Customer Name", value: AccountDetails?.customer_name },
    { key: "Gold OD A/C No", value: AccountDetails?.account_no },
    { key: "Date of Sanction", value: AccountDetails?.date_of_sanction },
    { key: "Sanctioned Amount", value: AccountDetails?.sanction_amount },
    { key: "Availed OD Amount", value: AccountDetails?.balance_amount },
    {
      key: "Amount available for Withdrawal",
      value: AccountDetails?.balance_outstanding,
    },
    ...(AccountDetails?.interest > 0 ? [{ key: "Interest", value: AccountDetails?.interest}] : []),
    ...(AccountDetails?.penal_interest > 0 ? [{ key: "Penal Interest", value: `₹${AccountDetails?.penal_interest}` }] : []),
    { key: "Bank Name", value: AccountDetails?.bank_details?.bank_name },
    { key: "Branch", value: AccountDetails?.branch_name },
    { key: "Bank A/C No", value: AccountDetails?.bank_details?.bene_acc_num },
    { key: "Interest Rate", value: AccountDetails?.interest_rate },
    { key: "Status", value: AccountDetails?.status, textStyle: "text-success" },
  ];

  const getReqBodyAndPath = (type) => {
    const device_data = {
      deviceId: navigator.userAgent,
      deviceType: "Web Portal",
    };
    let reqBody = {
      account_no: AccountDetails?.account_no,
      bank_account_no: AccountDetails?.bank_details?.bene_acc_num,
      device_data,
    };
    let path = "";
    switch (type) {
      case "pay":
        path = global.addToCart;
        reqBody = {
          account_id: AccountDetails?._id,
          type: "goldod",
          amount: InputData,
        };
        break;
      case "request":
        path = global.goldODRequestPayment;
        reqBody = {
          account_no: AccountDetails?.account_no,
          amount: InputData,
          device_data,
        };
        break;
      case "accept":
        path = global.acceptGoldODAccount;
        break;
      case "reject":
        path = global.rejectGoldODAccount;
        break;
    }
    return { reqBody, path };
  };

  const handleGetAccountDetails = async () => {
    try {
      setloading(true);
      const reqstBody = {
        account_no: accountId,
      };
      let response = await ManageApis(
        "post",
        global.getGoldOdAccountDetails,
        reqstBody,
        token
      );
      // console.log({ response })

      if (response?.response_data?.status) {
        if (response.response_data.data?.blocked?.status) {
          setloading(false);
          FailedPopup(response?.response_data?.data?.blocked?.message);
          navigation(-1);
        } else {
          const { data } = response.response_data || {};
          console.log("loan detail here --->", response.response_data);
          setAccountDetails(data);
          GetgoldOrnamentDet();
          setloading(false);
        }
      } else {
        setloading(false);
        FailedPopup(response,async=>{
          navigation(-1);
        } )
      }
    } catch (error) {
      setloading(false);
      FailedPopup(error?.message);
    }
  };
  const GetgoldOrnamentDet = async () => {
    try {
      const reqstBody = {
        account_no: accountId,
      };
      let response = await ManageApis(
        "post",
        global.goldODOrnamentDet,
        reqstBody,
        token
      );

      if (response?.response_data?.status) {
        const ornamentDetails = response?.response_data?.data?.securityDetails;
        setOrnamnentDetails(ornamentDetails);
        // setIsVisible(true);
      } else {
        FailedPopup(response);
      }
    } catch (error) {
      FailedPopup(error?.message);
    }
  };

  const handleGoldOdOrnament = () => {
    setIsVisible(true);
  };

  const isPending = () => {
    if (!Object.keys(AccountDetails).length) return;
    const key = AccountDetails?.bank_details?.status;
    if(key !== 0  && key !== 2 ){
      return false
    }
    return true
  }

  const SecondaryContainer = () => {
    if (!Object.keys(AccountDetails).length) return;
    const key = AccountDetails?.bank_details?.status;
    switch (key) {
      case 2:
        return (
          <ODLiveSection
            setModalProps={setModalProps}
            balanceAmt={AccountDetails?.balance_outstanding}
            availedAmt={AccountDetails?.balance_amount}
            AccountDetails={AccountDetails}
            getReqBodyAndPath={getReqBodyAndPath}
            InputData={InputData}
            setInputData={setInputData}
          />
        );
      case 0:
      case 3:
        return (
          <ODPendingSection
            setModalProps={setModalProps}
            bankAccountNo={AccountDetails?.bank_details?.bene_acc_num}
            isChecked={isChecked}
            AccountDetails={AccountDetails}
            setIsChecked={setIsChecked}
            getReqBodyAndPath={getReqBodyAndPath}
          />
        );
      default:
        return (
          <ODPendingSection
            setModalProps={setModalProps}
            bankAccountNo={AccountDetails?.bank_details?.bene_acc_num}
            isChecked={isChecked}
            AccountDetails={AccountDetails}
            setIsChecked={setIsChecked}
            getReqBodyAndPath={getReqBodyAndPath}
            isVerificationPending={key}
          />
        );
    }
  };

  const filteredList = useMemo(() => {
    return AccountDetailsItems.filter((item) => item);
  }, [AccountDetailsItems]);

  return (
    <Layout loading={loading}>
      {ModalProps?.open && (
        <PopupModal
          visible={ModalProps.open}
          handleClose={() => {
            setModalProps({ open: false });
            handleGetAccountDetails();
          }}
          title={ModalProps?.title}
          message={ModalProps?.message}
          buttons={ModalProps?.props}
        />
      )}
      <PopupModal
        visible={isVisible}
        handleClose={() => setIsVisible(false)}
        title={"Ornament Details"}
      >
        <OrnamentDetailsList ornamnentDetails={ornamnentDetails} />
      </PopupModal>

      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <BackBtn title={"Gold OD Details"} />
            <div className="col-12 col-sm-auto pt-3 pt-sm-2">
              <div className="d-flex gap-2">
                {isPending() && 
                <>
                  <div className="d-flex gap-3">
                    <Link
                      to={"/goldodwithdrawalhistory"}
                      state={{
                        account_no: AccountDetails?.account_no,
                        customerName: AccountDetails?.customer_name,
                      }}
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp"
                    >
                      View Gold OD Withdrawal History
                    </Link>
                  </div>
                  <div className="d-flex gap-3">
                    <Link
                      to={"/goldodpassbook"}
                      state={{
                        account_no: AccountDetails?.account_no,
                        customerName: AccountDetails?.customer_name,
                        AvailedAmt:AccountDetails?.balance_amount,
                        AmtAfterWithdrwal:AccountDetails?.balance_outstanding,
                      }}
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp"
                    >
                      View Gold OD Passbook
                    </Link>
                  </div>
                </>
                }

                <div
                  className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp"
                  onClick={handleGoldOdOrnament}
                >
                  View Gold OD Ornament Details
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
          <div className="row">
            {filteredList.map((item) => (
              <KeyValue item={item} textStyle={item?.textStyle} />
            ))}
          </div>
          {
  !AccountDetails?.remarks || AccountDetails?.remarks === "" 
    ? " " 
    : 
    <div className="bg_gray font_13 mb-1 p-2 rounded-3">
        <span>Remarks :</span>
        <span className="fw_600 ps-2">{AccountDetails.remarks}</span>
    </div>
}

        </div>

        <div>
          <div>
            {true && (
              <div>
                <div>{SecondaryContainer()}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GoldOdDetails;
