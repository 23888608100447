import React, { memo, useCallback, useContext, useState } from 'react'
import { ContextAPI } from '../../common/Context/ContextApi'
import { useEffect } from 'react'
import { ManageApis } from '../../common/APIS/ManageApis'
import { FailedPopup, customLogger } from '../../common/Utils/Utils'
import AutoComplete from './AutoComplete'

const BranchListDropdown = (props) => {
    const { token } = useContext(ContextAPI)
    const [branchList, setbranchList] = useState([])
    const [loader, setloader] = useState(false)
    const { apiParam } = props
    // useEffect(() => {
    //     GetBranches()
    // }, [])


    useEffect(
        () => {
            GetBranches()
            return (() => [
                setbranchList([])
            ])
        },
        [props.isUserBranch, apiParam],
    )
    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    // fetch Branch details
    const GetBranches = useCallback(() => {
        setloader(true)
        let path = props.isUserBranch ? global.listbranch + `?customerBranches=true${apiParam ? '&' + apiParam : ''}${props.isLoan ? '&' + 'scheme=loan' : ''} ` : `${global.listbranch}${apiParam ? '?' + apiParam : ''}` 
        ManageApis('get', path, '', token)
            .then((res) => {
                customLogger(res)
                if (res.response_data.status) {
                    //debugger
                    setloader(false)
                    let array = res.response_data.data.docs.map(item => ({ value: item.branch_id, key: item.branch_name }))
                    array = getUniqueListBy(array,"value")
                    setbranchList(array)
                } else {
                    setloader(false)
                    FailedPopup(res) 
                }
            })
            .catch((err) => {
                setloader(false)
                // FailedPopup((constantData.somethingWrongMessage))
            })
    }, [props.isUserBranch, apiParam])
    customLogger({ branchList })
    return (
        <AutoComplete {...props} type={'branch'} label={'Choose Branch'} data={branchList} />
    )
}

export default memo(BranchListDropdown)