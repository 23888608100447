import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Typography } from "@arco-design/web-react";
import BackBtn from "../../Components/BackBtn/BackBtn";
import Layout from "../../Components/Layout/Layout";
import { Nav, Tab } from "react-bootstrap";
import QuickPayForm from "./QuickPayForm";
import { ManageApis } from "../../common/APIS/ManageApis";
import { ContextAPI } from "../../common/Context/ContextApi";
import { FailedPopup } from "../../common/Utils/Utils";
import Favourite from "./Favourite";

const InitialValue = { loanList: [], chittal_list: [] };
function QuickPay() {
  const { getFavourite } = useContext(ContextAPI);
  const [IsLoading, setIsLoading] = useState(false);
  const [Tabindex, setTabIndex] = useState(0);
  const [isFormShowing, setIsFormShowing] = useState(false);
  const [index, setIndex] = useState(0);
  const [data, setData] = React.useState(InitialValue);
  const { Text } = Typography;

  const handleChangeTab = useCallback(
    (val) => {
      setTabIndex(val);
      setIndex(val);
      if (isFormShowing) {
        setIsFormShowing(false);
      }
    },
    [Tabindex, index,isFormShowing]
  );

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await getFavourite();
      if (result) {
        setData(result);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, [IsLoading, data]);

  useEffect(() => {
    getData();
  }, []);

  const payOther = () => {
    setIsFormShowing(true);
  };

  const renderTabContent = (type, list, isVisible, setVisible) =>
    !list.length || isVisible ? (
      <QuickPayForm
        index={Tabindex}
        setIsLoading={setIsLoading}
        setIsFormShowing={setIsFormShowing}
        isFormShowing={isFormShowing}
      />
    ) : (
      <>
        <Text style={{ color: "gray" }}>
          My Favourite {type === "chitty" ? "Chits" : "Loans"}
        </Text>
        <Favourite data={list} type={type} setLoading={setIsLoading} />
      </>
    );

  const isPayOtherButtonEnable = useMemo(() => {
    switch (Tabindex) {
      case 0:
        return (data.chittal_list.length > 0 && isFormShowing ===false) ? true : false;
        break;
      case 1:
        return (data.loanList.length > 0 && isFormShowing ===false) ? true : false;
        break;
      default:
        return true;
        break;
    }
  }, [data, Tabindex, isFormShowing]);
  return (
    <Layout loading={IsLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div>
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <BackBtn title={"Quick Pay"} />
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm">
            <Tab.Container defaultActiveKey="Chitty">
              <div className="mb-2 mb-sm-3 d-flex justify-content-between align-items-center">
                <Nav
                  className="gap-2 gap-sm-3 nav-tabs nav_tab_t3"
                  activeKey={Tabindex}
                >
                  <Nav.Item className="flex-fill flex-sm-grow-0">
                    <Nav.Link
                      eventKey="Chitty"
                      onClick={() => handleChangeTab(0)}
                    >
                      Chitty
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-fill flex-sm-grow-0">
                    <Nav.Link
                      eventKey="Loan"
                      onClick={() => handleChangeTab(1)}
                    >
                      Loan
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {isPayOtherButtonEnable === true && (
                  <button
                    className="btn btn-master flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded"
                    onClick={() => payOther()}
                  >
                    Pay Other
                  </button>
                )}
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="Chitty">
                    {Tabindex === 0 &&
                      renderTabContent(
                        "chitty",
                        data.chittal_list,
                        isFormShowing,
                        setIsFormShowing
                      )}
                  </Tab.Pane>

                  <Tab.Pane eventKey="Loan">
                    {Tabindex === 1 &&
                      renderTabContent(
                        "loan",
                        data.loanList,
                        isFormShowing,
                        setIsFormShowing
                      )}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default QuickPay;
