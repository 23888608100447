import React from 'react';

const OrnamentDetail = ({ label, value }) => (
  <div className="col-12 col-sm-3 pe-sm-2 px-0">
    <h6 className="font_11 font_400">{label}</h6>
    <h1 className="font_15 font_500">{value}</h1>
  </div>
);

const OrnamentDetailsList = ({ ornamnentDetails }) => (
  <>
    {ornamnentDetails && ornamnentDetails.length > 0 ? (
      ornamnentDetails.map((item, index) => (
        <div className='col'>
          <div key={index} className="border mb-3 px-3 py-2 rounded shadow-sm">
            <div className="mx-0 pt-2 row">
              <OrnamentDetail label="Ornament Type" value={item.ornamentType} />
              <OrnamentDetail label="No of items" value={item.noOfItems} />            
              <OrnamentDetail label="Gross Weight" value={item.grossWeight} />      
              <OrnamentDetail label="Net Weight" value={item.netWeight} />       
            </div>
            {/* <div className="mx-0 pt-2 row">
              
              <OrnamentDetail label="Rate per gram" value={item.ratePerGram} />
              <OrnamentDetail label="Purity" value={item.purity} />             
            </div>
            <div className="mx-0 pt-2 row">
              <OrnamentDetail label="Amount by KSFE rate" value={item.amountByKsfeRate} />
              <OrnamentDetail label="Amount by daily rate" value={item.amountByDailyRate} />
            </div> */}
          </div>
        </div>
      ))
    ) : (
      <div>No ornament details available</div>
    )}
  </>
);

export default OrnamentDetailsList;
